<template>
  <!--footer section start-->
  <!--when you want to remove subscribe newsletter container then you should remove .footer-with-newsletter class-->
  <footer
    id="footer"
    class="footer-1 gradient-bg ptb-60"
    v-bind:class="{ 'footer-with-newsletter': showSubscribe }"
  >
    <!--subscribe newsletter start-->
    <div class="container" v-show="showSubscribe">
      <div class="row newsletter-wrap primary-bg rounded shadow-lg p-5">
        <div class="col-md-6 col-lg-7 mb-4 mb-md-0 mb-sm-4 mb-lg-0">
          <div class="newsletter-content text-white">
            <h3 class="mb-0 text-white">Subscribe our Newsletter</h3>
            <p class="mb-0">
              We’re a team of non-cynics who truly care for our work.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-5">
          <form class="newsletter-form position-relative">
            <input
              type="text"
              class="input-newsletter form-control"
              placeholder="Enter your email"
              name="email"
              required
              autocomplete="off"
            />
            <button type="submit" class="disabled">
              <i class="fas fa-paper-plane"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
    <!--subscribe newsletter end-->

    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
          <a href="#" class="navbar-brand mb-2">
            <img
              src="../../assets/img/logo-white.png"
              alt="logo"
              class="img-fluid"
            />
          </a>
          <br />
          <p>
            {{ $t("footer.desc") }}
          </p>
          <div
            class="
              list-inline
              social-list-default
              background-color
              social-hover-2
              mt-2
            "
          >
            <li class="list-inline-item">
              <a class="twitter" href="https://twitter.com/u9_BlockChain">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="youtube" href="#">
                <i class="fab fa-youtube"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="linkedin" href="#">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
            <!-- <li class="list-inline-item">
              <a class="dribbble" href="#">
                <i class="fab fa-dribbble"></i>
              </a>
            </li> -->
          </div>
        </div>
        <div class="col-md-12 col-lg-8">
          <div class="row mt-0">
            <!-- <div
              class="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0"
            >
              <h6 class="text-uppercase">Resources</h6>
              <ul>
                <li>
                  <a href="#">Help</a>
                </li>
                <li>
                  <a href="#">Events</a>
                </li>
                <li>
                  <a href="#">Live sessions</a>
                </li>
                <li>
                  <a href="#">Open source</a>
                </li>
                <li>
                  <a href="#">Documentation</a>
                </li>
              </ul>
            </div> -->
            <!-- <div
              class="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0"
            >
              <h6 class="text-uppercase">Products</h6>
              <ul>
                <li>
                  <a href="#about">Pricing</a>
                </li>
                <li>
                  <a href="#">Navigation</a>
                </li>
                <li>
                  <a href="#">AI Studio</a>
                </li>
                <li>
                  <a href="#">Page Speed</a>
                </li>
                <li>
                  <a href="#">Performance</a>
                </li>
              </ul>
            </div> -->
            <div
              class="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0"
            >
              <h6 class="text-uppercase">U9Club</h6>
              <ul>
                <li>
                  <a href="#platform">{{$t('tab.about')}}</a>
                </li>
                <li>
                  <a href="#about">{{$t('tab.idea')}}</a>
                </li>
                <li>
                  <a href="#promo">{{$t('tab.service')}}</a>
                </li>
              </ul>
            </div>
            <div class="col-sm-6 col-md-3 col-lg-3">
              <h6 class="text-uppercase">{{$t('footer.teamwork')}}</h6>
              <ul>
                <li>
                  <a href="#media">{{$t('tab.media')}}</a>
                </li>
                <li>
                  <a href="#features">{{$t('tab.project')}}</a>
                </li>
                <li>
                  <a href="">{{$t('footer.teamwork-us')}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end of container-->
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
  props: {
    showSubscribe: {
      type: Boolean,
      default: false,
    },
  },
};
</script>