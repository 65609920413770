
import VueI18n from 'vue-i18n';
import Vue from 'vue'
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: localStorage.getItem("lang") || "en", // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
    messages: {
      'chs': require('./chs.json'), // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
      'en': require('./en.json')
    }
  });

  export default i18n;