<template>
  <div class="col-lg-8 col-md-8">
    <h3>{{ $t("tool.transfer.tipTitle") }}</h3>
    <b-row class="align-items-center justify-center">
      <b-col col="6" style="padding: 10px">
        <button
          type="submit"
          class="btn btn-brand-02"
          style="width: 100%"
          @click="clickModal"
        >
          {{ connected ? walletAddress : $t("global.connect") }}
        </button>
      </b-col>
      <b-col col="6" class="">
        <div>
          {{ $t("global.balance") }} :
          <span class="balance">{{ balance }}</span> Matic
        </div>
      </b-col>
    </b-row>
    <div class="tip" v-html="$t('tool.transfer.tip')"></div>
    <div class="input-group mt-4">
      <input
        type="text"
        class="form-control"
        :placeholder="$t('wallet.tokenContractTip')"
        v-model="contractAddress"
        @input="inputContract"
      />
    </div>
    <div style="padding: 10px">
      Token: <span class="balance">${{ tokenInfo.symbol }}</span>
      {{ $t("global.balance") }} :
      <span class="balance"> {{ tokenInfo.balance }}</span>
    </div>
    <div>
      <textarea
        style="width: 100%; min-height: 300px; margin-top: 30px"
        v-model="transferInfo"
      ></textarea>

      <button
        type="submit"
        class="btn btn-brand-02"
        style="width: 100%"
        @click="transfer"
      >
        {{ $t("tool.transferBatches") }}
      </button>
    </div>

    <ConnectModal v-if="modalShow" @closeShow="clickModal" />
  </div>
</template>
  
  <script>
import ConnectModal from "@/components/ConnectModal.vue";
import BigNumber from "bignumber.js";
export default {
  name: "BatchTransfer",
  components: { ConnectModal },
  data: function () {
    return {
      message: "Copy These Text",
      walletList: undefined,
      selectedWalletIndex: 0,
      selectWalletList: ["Polygon"],
      tokenInfo: { symbol: "", balance: "", decimals: undefined },
      contractAddress: "",
      walletListText: "",
      modalShow: false,
      transferInfo: "",
      approved: "false",
    };
  },
  computed: {
    walletAddress() {
      let address = this.$store.state.user.coinbase;
      if (address)
        return (
          address.substring(0, 5) +
          "..." +
          address.substring(address.length - 5, address.length)
        );
      else return "";
    },
    balance() {
      return this.$store.state.user.balance;
    },
    connected() {
      return this.$store.state.connected;
    },
  },
  methods: {
    clickModal() {
      this.modalShow = !this.modalShow;
    },

    inputContract() {
      if (
        this.contractAddress.length >= 32 &&
        this.contractAddress.toLowerCase().startsWith("0x")
      ) {
        this.$web3SDK.getBalance(this.contractAddress).then((tokenInfo) => {
          this.tokenInfo = tokenInfo;
        });
      }
    },
    async transfer() {
      let infos = this.transferInfo.split("\n");
      let address = [];
      let amounts = [];
      for (const info of infos) {
        address.push(info.split(",")[0]);
        amounts.push(info.split(",")[1]);
      }
      let totalAmount = new BigNumber(0);
      amounts.forEach(
        (amount) => (totalAmount = totalAmount.plus(new BigNumber(amount)))
      );
      let allowance = await this.$web3SDK.allowancePayToken(
        this.contractAddress,
        this.tokenInfo.decimals
      );
      let allow = !allowance.error && allowance >= totalAmount;
      if (!allow) {
        allow = await this.$web3SDK.approvePayToken(
          this.contractAddress,
          parseFloat(totalAmount.minus(new BigNumber(allowance).toString())),
          this.tokenInfo.decimals
        );
      }
      if (allow) {
        let result = await this.$web3SDK.batchTransfer(
          address,
          amounts,
          this.contractAddress,
          this.tokenInfo.decimals
        );
        if (!result.error) {
          alert(`Success!, txId: ${result.tx}`);
        }
        console.log(result);
      }
    },
    selectWallet(e) {
      this.walletListText = "";
      this.selectedWalletIndex = e.target.value;
    },
  },
};
</script>
  
  <style scoped lang="scss">
.tip {
  background: rgb(230, 228, 228);
  padding: 5px 10px;
  color: #1b2b4e;
}
.select {
  margin: 10px 0;
}
.balance {
  color: #4f1bc5;
}
.wallets {
  font-size: 13px;
}
.walletIndex {
  background: linear-gradient(75deg, #4f1bc5, #7b17c5) !important;
  padding: 10px;
  color: white;
  margin-top: 10px;
}
.center {
  text-align: center;
}
</style>