import utils_web3 from "@/util/web3/wallet";
var Web3 = require("web3");
import utils from "./utils";
import truffle_contract from "@truffle/contract";
import store from "@/store";
import constants from "./constants";
const eth_util = require("ethereumjs-util");
import BigNumber from "bignumber.js";

export default {

  getAccountAddress() {
    return store.state.user.coinbase;
  },
  getContractAddress(type) {
    return store.state.contractList[type]
  },
  async setApprovalForAll(approved) {
    let contract = await this.getContract(0);
    if (contract.error) return contract;

    try {
      return await contract.setApprovalForAll(this.getContractAddress(1), approved, {
        from: this.getAccountAddress(),
      });
    } catch (e) {
      return {
        error: e.message
      };
    }
  },
  async isApprovedForAll() {
    let contract = await this.getContract(0);
    if (contract.error) return contract;
    try {
      return await contract.isApprovedForAll(this.getAccountAddress(), this.getContractAddress(1));
    } catch (e) {
      return {
        error: e.message
      };
    }
  },
  getFullAsset(asset) {
    let abi = utils.contractAbi(asset.type);
    asset.abi = abi;
    return asset;
  },
  async getBalance(tokenContract) {
    try {
      var web3 = await utils_web3.getWeb3();
      if (!tokenContract) {
        let balance = await web3.eth.getBalance(this.getAccountAddress());
        return utils.decimals(this.fromEther(balance.toString()));
      } else {
        let contract = await this.contractAt(tokenContract, utils.contractAbi(1));
        if (contract.error) return contract;
        let balance = await contract.balanceOf(this.getAccountAddress());
        let symbol = await contract.symbol();
        let decimals = await contract.decimals();
        return {
          symbol,
          decimals,
          balance: utils.decimals(this.fromWei(balance.toString()) / Math.pow(10, decimals))
        };
      }
    } catch (e) {
      console.log(e)
      return 0;
    }
  },
  async getContract(type) {
    return await this.contractAt(store.state.contractList[type], utils.contractAbi(type));
  },
  async contractAt(address, abi) {
    let contract = truffle_contract(abi);
    var web3 = utils_web3.getWeb3();
    contract.setProvider(web3.currentProvider);
    try {
      // if (store.state.eip1559) {
      //   var gasPrice = await web3.eth.getGasPrice();
      //   contract.defaults({
      //     gasPrice: gasPrice,
      //   });
      // }
      return await contract.at(address);
    } catch (e) {
      return {
        error: e.message
      };
    }
  },
  async allowancePayToken(tokenContract, decimals) {
    let contract = await this.contractAt(tokenContract, utils.contractAbi(1));
    if (contract.error) return contract;
    try {
      let allowance = await contract.allowance(this.getAccountAddress(), this.getContractAddress(0));
      return this.fromWei(allowance.toString()) / Math.pow(10, decimals);
    } catch (e) {
      return {
        error: e.message
      };
    }
  },
  async approvePayToken(tokenContract, amount, decimals) {
    let contract = await this.contractAt(tokenContract, utils.contractAbi(1));
    if (contract.error) return contract;
    try {
      amount = (amount * Math.pow(10, decimals)).toString();
      console.log(amount)
      return await contract.approve(this.getContractAddress(0), amount, {
        from: this.getAccountAddress(),
      });
    } catch (e) {
      return {
        error: e.message
      };
    }
  },
  async batchTransfer(addresses, amounts, tokenContract, decimals) {

    try {
      let contract = await this.getContract(0);
      if (contract.error) return contract;
      if (tokenContract) {
        let decimalsAmount = []
        amounts.forEach(amount => {
          decimalsAmount.push((parseFloat(amount) * Math.pow(10, decimals)).toString());
        });
        let gas = await contract.sendErc20.estimateGas(tokenContract, addresses, decimalsAmount, {
          from: this.getAccountAddress(),
        });
        gas = new BigNumber(gas).multipliedBy(new BigNumber(await this.getFeePercent())).plus(1).toFixed(0);
        var web3 = await utils_web3.getWeb3();

        var gasPrice = await web3.eth.getGasPrice();
        let fee = new BigNumber(gas).multipliedBy(new BigNumber(gasPrice)).toFixed(0)
        // return gas;
        return await contract.sendErc20(tokenContract, addresses, decimalsAmount, {
          from: this.getAccountAddress(),
          value: fee,
        });

      }


    } catch (e) {
      console.log(e)
      return {
        error: e.message
      };
    }

  },

  async getFeePercent() {
    try {
      let contract = await this.getContract(0);
      if (contract.error) return contract;
      const percent = await contract.feePercent();
      return percent / 100;
    } catch (e) {
      return {
        error: e.message
      };
    }
  },
  parseSignatureHex(signature) {
    if (!signature) {
      return {
        v: 0,
        r: "0x0000000000000000000000000000000000000000000000000000000000000000",
        s: "0x0000000000000000000000000000000000000000000000000000000000000000",
      };
    }
    const validVParamValues = [27, 28];
    const ecSignatureRSV = _parseSignatureHexAsRSV(signature);
    if (validVParamValues.includes(ecSignatureRSV.v)) {
      return ecSignatureRSV;
    }
    const ecSignatureVRS = _parseSignatureHexAsVRS(signature);
    if (validVParamValues.includes(ecSignatureVRS.v)) {
      return ecSignatureVRS;
    }
    throw new Error("Invalid signature");

    function _parseSignatureHexAsVRS(signatureHex) {
      const signatureBuffer = eth_util.toBuffer(signatureHex);
      let v = signatureBuffer[0];
      if (v < 27) {
        v += 27;
      }
      const r = signatureBuffer.slice(1, 33);
      const s = signatureBuffer.slice(33, 65);
      const ecSignature = {
        v,
        r: eth_util.bufferToHex(r),
        s: eth_util.bufferToHex(s),
      };
      return ecSignature;
    }

    function _parseSignatureHexAsRSV(signatureHex) {
      const {
        v,
        r,
        s
      } = eth_util.fromRpcSig(signatureHex);
      const ecSignature = {
        v,
        r: eth_util.bufferToHex(r),
        s: eth_util.bufferToHex(s),
      };
      return ecSignature;
    }
  },
  fromEther(data) {
    return parseFloat(Web3.utils.fromWei(data, 'ether'));
  },
  fromWei(data) {
    return parseFloat(Web3.utils.fromWei(data, 'wei'));
  },
  toEther(data) {
    return Web3.utils.toWei(data.toString(), 'ether');
  },
  NULL_ADDRESS() {
    return constants.NULL_ADDRESS;
  },
};