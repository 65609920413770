<template>
  <div
    id="partners"
    class="feature-section pd30"
    v-bind:class="{ 'gray-light-bg': isGray }"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-9">
          <div class="section-heading text-center mb-5">
            <h2>{{ $t("partners.title") }}</h2>
            <p>{{ $t("partners.desc") }}</p>
          </div>
        </div>
      </div>
      <div class="justify-content-center">
        <div class="first">
          <div class="row">
            <div class="col-3">
              <img
                src="../../assets/img/first_00.jpg"
                alt="app icon"
                width="100%"
                class="mr-3"
              />
            </div>
            <div class="col-3">
              <img
                src="../../assets/img/first_01.jpg"
                alt="app icon"
                width="100%"
                class="mr-3"
              />
            </div>
            <div class="col-3">
              <img
                src="../../assets/img/first_02.jpg"
                alt="app icon"
                width="100%"
                class="mr-3"
              />
            </div>
            <div class="col-3">
              <img
                src="../../assets/img/first_03.jpg"
                alt="app icon"
                width="100%"
                class="mr-3"
              />
            </div>
          </div>
        </div>
        <div class="second mt30">
          <div>
            <div class="row justify-center">
              <div class="col-3 text-center">
                <img
                  src="../../assets/img/second_00.jpg"
                  alt="app icon"
                  width="100%"
                  class="mr-3"
                />
              </div>
              <div class="col-3">
                <img
                  src="../../assets/img/second_01.jpg"
                  alt="app icon"
                  width="100%"
                  class="mr-3"
                />
              </div>
              <div class="col-3">
                <img
                  src="../../assets/img/second_02.jpg"
                  alt="app icon"
                  width="100%"
                  class="mr-3"
                />
              </div>
              <div class="col-3">
                <img
                  src="../../assets/img/second_03.png"
                  alt="app icon"
                  width="100%"
                  class="mr-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="second mt30">
        <div>
          <div class="row">
            <div class="col-3">
              <img
                src="../../assets/img/third_00.jpg"
                alt="app icon"
                width="100%"
                class="mr-3"
              />
            </div>
            <div class="col-3">
              <img
                src="../../assets/img/third_01.jpg"
                alt="app icon"
                width="100%"
                class="mr-3"
              />
            </div>
            <div class="col-3">
              <img
                src="../../assets/img/third_02.jpg"
                alt="app icon"
                width="100%"
                class="mr-3"
              />
            </div>
            <div class="col-3">
              <img
                src="../../assets/img/third_04.jpg"
                alt="app icon"
                width="100%"
                class="mr-3"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="second mt30">
        <div class="row mt20">
          <div class="col-6">
            <img
              src="../../assets/img/third_05.jpg"
              alt="app icon"
              width="100%"
              class="mr-3"
            />
          </div>
          <div class="col-6">
            <img
              src="../../assets/img/third_06.jpg"
              alt="app icon"
              width="100%"
              class="mr-3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="scss">
.black {
  color: #333333;
}
.pd30 {
  padding: 30px 0px 50px 0px;
}
.third {
  display: flex;
  justify-content: space-around;
}
.second_one {
  display: flex;
  justify-content: space-around;
}
.second_two {
  display: flex;
  font-size: 24px;
  font-weight: bolder;
  line-height: 100px;
}
.second {
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #bbbbbb;
}
.blue {
  background-color: #63a4d7;
}
.deep_red {
  background-color: #b135be;
}
.purple {
  background-color: #8371e8;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.rectangle {
  width: 10px;
  height: 180px;
  position: relative;
  left: -84px;
}
.rectangle_one {
  width: 10px;
  height: 180px;
  float: left;
  margin-left: -84px;
  margin-top: 15px;
}
.first {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #bbbbbb;
}
.flexdir {
  flex-direction: row;
}
.col-lg-4 {
  /* max-width: 50%; */
}
.center {
  text-align: center;
}
</style>
