<template>
    <div id="games" class="feature-section pd30" v-bind:class="{ 'gray-light-bg' : isGray }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-9">
                    <div class="section-heading text-center mb-5">
                        <h2>{{$t('games.title')}}</h2>
                        <p>{{$t('games.desc')}}</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col-lg-4 col-md-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Left_AMA_00.jpg" alt="app icon" width="100%" class="mr-3" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Left_AMA_01.jpg" alt="app icon" width="100%" class="mr-3" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Left_AMA_03.jpg" alt="app icon" width="100%" class="mr-3" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Right_AMA_00.jpg" alt="app icon" width="100%" class="mr-3" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Right_AMA_01.jpg" alt="app icon" width="100%" class="mr-3" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Right_AMA_03.jpg" alt="app icon" width="100%" class="mr-3" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Features",
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style scoped lang="scss">
    .pd30{
        padding: 30px 0px;
    }
    .flexdir {
        flex-direction: row;
    }
    .col-lg-4 {
        /* max-width: 50%; */
    }
    .center {
        text-align: center;
    }
</style>
