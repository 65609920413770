var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('nav',{staticClass:"navbar navbar-expand-lg fixed-top",class:{
      affix: _vm.hasAffix,
      'custom-nav': _vm.coloredLogo,
      'bg-transparent': !_vm.coloredLogo,
    }},[_c('div',{staticClass:"container"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.coloredLogo
              ? '../../assets/img/logo-color.png'
              : '../../assets/img/logo-white.png',"alt":"logo"}})]),_c('button',{staticClass:"navbar-toggler",class:{ collapsed: _vm.collapsed },attrs:{"type":"button"},on:{"click":_vm.mobileNavClicked}},[_c('span',{staticClass:"ti-menu"})]),_c('div',{staticClass:"collapse navbar-collapse h-auto",class:{ show: !_vm.collapsed },attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto menu"},[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("tab.home")))])],1),_c('li',[_c('a',{on:{"click":function($event){return _vm.goAnchor('promo')}}},[_vm._v(_vm._s(_vm.$t("tab.service")))])]),_c('li',[_c('a',{on:{"click":function($event){return _vm.goAnchor('/tool')}}},[_vm._v(_vm._s(_vm.$t("tab.tool")))])]),_c('li',[_c('a',{on:{"click":function($event){return _vm.goAnchor('about')}}},[_vm._v(_vm._s(_vm.$t("tab.idea")))])]),_c('li',[_c('a',{on:{"click":function($event){return _vm.goAnchor('media')}}},[_vm._v(_vm._s(_vm.$t("tab.media")))])]),_c('li',[_c('a',{on:{"click":function($event){return _vm.goAnchor('features')}}},[_vm._v(_vm._s(_vm.$t("tab.project")))])]),_c('li',[_c('a',{on:{"click":function($event){return _vm.goAnchor('platform')}}},[_vm._v(_vm._s(_vm.$t("tab.about")))])]),_c('li',[_c('a',{on:{"click":function($event){return _vm.goAnchor('process')}}},[_vm._v(_vm._s(_vm.$t("tab.process")))])]),_c('li',[_c('a',{on:{"click":function($event){return _vm.goAnchor('footer')}}},[_vm._v(_vm._s(_vm.$t("tab.contact")))])]),_c('li',[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t("lang.lang")))]),_c('ul',{staticClass:"sub-menu"},[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_c('span',{on:{"click":function($event){return _vm.changeLang('chs')}}},[_vm._v(_vm._s(_vm.$t("lang.chs")))])])],1),_c('li',[_c('router-link',{attrs:{"to":"/"}},[_c('span',{on:{"click":function($event){return _vm.changeLang('en')}}},[_vm._v(" "+_vm._s(_vm.$t("lang.en")))])])],1)])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }