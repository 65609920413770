import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/fontawesome-min.css";
import tools from './util/tools';
import sdk from './util/web3/sdk';
import i18n from './lang';
import BCol from "./components/Helper/BCol.vue";
import BRow from "./components/Helper/BRow.vue";

// import BaseButton from "./components/Helper/BaseButton.vue";
import BContainer from "./components/Helper/BContainer.vue";


/* asset imports */

import './assets/scss/main.scss';
/* plugins */
import VueScrollTo from 'vue-scrollto';
import VueYoutube from 'vue-youtube';
import BackToTop from 'vue-backtotop';
import VueClipboard from 'vue-clipboard2'

import {
  gsap
} from 'gsap';
import {
  CSSRulePlugin
} from "gsap/CSSRulePlugin";

Vue.use(VueScrollTo);
Vue.use(VueYoutube);
Vue.use(BackToTop);
// Vue.use(VueI18n);
Vue.use(VueClipboard);

Vue.component("BRow", BRow);
// Vue.component("BButton", BaseButton);
Vue.component("BCol", BCol);
Vue.component("BContainer", BContainer);

gsap.registerPlugin(CSSRulePlugin);

Vue.config.productionTip = false;
Vue.prototype.$tools = tools;
Vue.prototype.$web3SDK = sdk;


new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');