<template>
  <div class="col-lg-8 col-md-8">
    <h3>{{ $t("tool.wallet.tipTitle") }}</h3>
    <div class="tip">{{ $t("tool.wallet.tip") }}</div>
    <div>
      <div class="select">{{ $t("tool.wallet.select") }}</div>
      <select
        class="custom-select"
        v-model="selectedWalletIndex"
        @change="selectWallet"
      >
        <option
          v-for="(item, index) in selectWalletList"
          :key="index"
          :value="index"
        >
          {{ item }}
        </option>
      </select>
      <p />
      <span class="wallets" v-if="selectedWalletIndex == 0">
        (ETH/BSC/Polygon/Aurora/Avalanche/Fantom/Celo....)</span
      >
    </div>
    <div class="input-group mt-4">
      <input
        type="text"
        class="form-control"
        :placeholder="$t('tool.wallet.number')"
        v-model="walletNumber" 
      />
    </div>
    <div>
      <div class="row">
        <div class="col-6" style="padding: 10px">
          <button
            type="submit"
            class="btn btn-brand-02"
            style="width: 100%"
            @click="create('apt', 10)"
          >
            {{ $t("tool.wallet.create") }}
          </button>
        </div>
        <div class="col-6" style="padding: 10px">
          <button
            v-clipboard:copy="walletListText"
            class="btn btn-brand-01"
            style="width: 100%"
            v-show="walletList"
          >
            {{ $t("tool.wallet.copy") }}
          </button>
        </div>
      </div>

      <div v-for="(wallet, index) in walletList" :key="index">
        <div class="walletIndex">Wallet {{ index }}</div>
        <div class="select">{{ $t("tool.wallet.address") }}</div>
        <input
          type="text"
          class="form-control"
          readonly="true"
          :value="wallet.address"
        />
        <div class="select">{{ $t("tool.wallet.privateKey") }}</div>
        <input
          type="text"
          class="form-control"
          readonly="true"
          :value="wallet.privateKey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import walletCreate from "../../util/walletCreate.js";

export default {
  name: 'BatchCreateWallet',
  data: function () {
    return {
      message: "Copy These Text",
      walletList: undefined,
      selectedWalletIndex: 0,
      selectWalletList: ["EVM Wallet", "Aptos Wallet"],
      walletNumber: 10,
      walletListText: "",
    };
  },
  methods: {
    async create() {
      let type = this.selectedWalletIndex == 1 ? "apt" : "evm";
      this.walletList = await walletCreate.create(type, this.walletNumber);
      for (let i = 0; i < this.walletList.length; i++) {
        this.walletListText += ` ${type.toUpperCase()} Wallet ${
          i + 1
        } \n address: ${this.walletList[i].address} \n privateKey:  ${
          this.walletList[i].privateKey
        } \n \n`;
      }
    },
    selectWallet(e) {
      this.walletListText = "";
      this.selectedWalletIndex = e.target.value;
    },
  },
};
</script>

<style scoped lang="scss">
.tip {
  background: rgb(230, 228, 228);
  padding: 5px 10px;
  color: #1b2b4e;
}
.select {
  margin: 10px 0;
}
.wallets {
  font-size: 13px;
}
.walletIndex {
  background: linear-gradient(75deg, #4f1bc5, #7b17c5) !important;
  padding: 10px;
  color: white;
  margin-top: 10px;
}
</style>