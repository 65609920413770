import sdk from "@/util/web3/sdk/index.js";

export default {
  gitBook(state) {
    return {
      zh: "https://snake-dance-nft.gitbook.io/snake-dance-nft/huan-ying-lai-dao-snake-dance-nft-shi-jie",
      en: "https://snake-dance-nft.gitbook.io/snake-dance-nft-en/welcome-to-the-snake-dance-nft-world",
      kor: "https://snake-dance-nft.gitbook.io/snake-dance-nft-en/welcome-to-the-snake-dance-nft-world",
    };
  },
  social(state) {
    return {
      twitter: "https://twitter.com/SnakeDancerNFT",
      discord: "https://discord.gg/xm3AR9d6kM",
    };
  },

  payToken: (state) => (address) => {
    if (!address) return;
    for (var i = 0; i < state.payTokens.length; i++) {
      let payToken = state.payTokens[i];
      if (payToken.address.toLocaleLowerCase() == address.toLocaleLowerCase())
        return payToken;
    }
  },
  payTokenBySymbol: (state) => (symbol) => {
    for (var i = 0; i < state.payTokens.length; i++) {
      let payToken = state.payTokens[i];
      if (payToken.symbol == symbol) return payToken;
    }
  },
  walletType: (state) => (type) => {
    let metaMask = {
      name: "MetaMask",
      img: require("@/assets/img/icon/MetaMask.svg"),
    };
    switch (type) {
      case 0:
        return metaMask;
      case 1:
        return {
          name: "Nabox Wallet",
          img: require("@/assets/img/icon/nabox.png"),
        };
      case 2:
        return {
          name: "CLV Wallet",
          img: require("@/assets/img/icon/clvwallet.png"),
        };
      default:
        return metaMask;
    }
  },
  defaultSalePayToken: (state) => () => {
    let paytoken = state.defalutPayToken;
    if (paytoken) return paytoken;
    let paytokens = state.payTokens;
    let _paytokens = paytokens.filter(function (token) {
      return !token.erc20;
    });
    if (_paytokens.length) return _paytokens[0];
    return paytokens[0];
  },
  defaultBidPayToken: (state) => () => {
    let paytoken = state.defalutPayToken;
    if (paytoken && paytoken.erc20) return paytoken;
    let paytokens = state.payTokens;
    let _paytokens = paytokens.filter(function (token) {
      return token.erc20;
    });
    return _paytokens[0];
  },
  getBalance: (state) => (address) => {
    if (address == sdk.NULL_ADDRESS()) return state.ethBalance;
    return state.erc20Balance[address];
  },
  category: (state) => (categoryId) => {
    for (var i = 0; i < state.categorys.length; i++) {
      let category = state.categorys[i];
      if (category.id == categoryId) return category;
    }
  },
};
