<template>
    <div id="features" class="feature-section ptb-100" v-bind:class="{ 'gray-light-bg' : isGray }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-9">
                    <div class="section-heading text-center mb-5">
                        <h2>{{$t('project.title')}}</h2>
                        <p>{{$t('project.desc')}}</p>
                    </div>
                </div>
            </div>
            
            <div class="row align-items-center justify-content-md-center">
                <div class="col-4  ">
                    <div class="row">
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Solchicks.png" alt="app icon" width="100" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-33')}}</h5>
                                    <!-- <p>{{$t('project.desc-1')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/dora.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div>
                                    <h5 class="mb-2">{{$t('project.title-2')}}</h5>
                                    <!-- <p>{{$t('project.desc-2')}}.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/opul.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-3')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/imx.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-7')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/vision.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-9')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/lez.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-10')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/STRIPS.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-13')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/DinoSwap.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-14')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/CHRONICLE.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-27')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/NiiFi.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-28')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/FunCraft.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-29')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/EndlessBattlefield.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-35')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-5 d-none d-sm-none d-md-block d-lg-block">
                    <div class="position-relative pb-md-5 py-lg-0">
                        <img alt="Image placeholder" src="../../assets/img/app-mobile-image.png" class="img-center img-fluid" />
                    </div>
                </div> -->
                <div class="col-4  ">
                    <div class="row">
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/mask.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-4')}}</h5>
                                    <!-- <p>{{$t('project.desc-4')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/kaby.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-5')}}</h5>
                                    <!-- <p>{{$t('project.desc-5')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/cere.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-5')}}</h5>
                                    <!-- <p>{{$t('project.desc-6')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/flow.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-8')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/spofi.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-11')}}</h5>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/MeMusic.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-36')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/logo_BK.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-12')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Alethea-AI.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-15')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Manta-Network.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-16')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                          <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/UMBRELLA.jpeg" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div>
                                    <h5 class="mb-2">{{$t('project.title-26')}}</h5>
                                    <!-- <p>{{$t('project.desc-2')}}.</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/SDW.jpeg" alt="app icon" class="img-fluid mr-3" width="50">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-34')}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4  ">
                    <div class="row">
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/NFT20.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-17')}}</h5>
                                    <!-- <p>{{$t('project.desc-4')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Original-Games.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-18')}}</h5>
                                    <!-- <p>{{$t('project.desc-5')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Redhill-Games.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-19')}}</h5>
                                    <!-- <p>{{$t('project.desc-6')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/zamio.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-20')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Chingari.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-21')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/DeRace.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-22')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/ROSEON.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-23')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Algorand.jpeg" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-24')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/mars4.png" alt="app icon" class="img-fluid mr-3" width="50">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-1')}}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/COLONY.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-30')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/Gamefam.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-31')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/PlayPack.png" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-32')}}</h5>
                                    <!-- <p>{{$t('project.desc-3')}}</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4  ">
                    <div class="row">
                        <div class="col-12">
                            <div class="center align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                <div style="marginBottom: 10px;">
                                    <img src="../../assets/img/MINA.jpeg" alt="app icon" width="50" class="img-fluid mr-3">
                                </div>
                                <div class="icon-text">
                                    <h5 class="mb-2">{{$t('project.title-25')}}</h5>
                                    <!-- <p>{{$t('project.desc-1')}}</p> -->
                                </div>
                            </div>
                        </div>
                      
                      
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Features",
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style scoped lang="scss">
    .flexdir {
        flex-direction: row;
    }
    .col-lg-4 {
        max-width: 25%;
    }
    .center {
        text-align: center;
    }
</style>
