<template>
  <!-- Connect Wallet Modal -->
  <div class="connect_modal">
    <div
      class="modal fade show"
      id="connectModal"
      tabindex="-1"
      aria-hidden="true"
      style="display: block; padding-right: 0px"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal_overlay">
            <div class="modal_header">
              <h2>{{ $t("global.connect") }}</h2>
              <button data-bs-dismiss="modal" aria-label="Close" @click="close">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="modal_body text-center">
              <p>
                {{ $t("wallet.title") }}
              </p>
              <div class="connect-section">
                <ul class="heading-list" v-if="!connected">
                  <a href="#" @click="connect">
                    <li>
                      <span
                        ><img
                          src="@/assets/img/icon/MetaMask.svg"
                          alt="Meta-mask-Image" /></span
                      >MetaMask
                    </li></a
                  >

                  <li class="un-activate">
                    <span
                      ><img
                        src="@/assets/img/icon/Trust_Wallet.svg"
                        alt="Trust-Image" /></span
                    >Trust Wallet
                  </li>
                  <li class="un-activate">
                    <span
                      ><img
                        src="@/assets/img/icon/WalletConnect.svg"
                        alt="Wallet-Image" /></span
                    >WalletConnect
                  </li>
                </ul>
                <ul class="heading-list" v-else>
                  <li>
                    <span
                      ><img :src="walletType.img" alt="Meta-mask-Image"
                    /></span>
                    <span class="address">{{ walletAddress }}</span>
                  </li>
                  <div class="disconnect" @click="disconnect">
                    {{ $t("global.disconnect") }}
                  </div>
                </ul>
              </div>

              <h6>
                {{ $t("wallet.subTitle") }}

                <b-row class="justify-content-center">
                  <b-col col="4">
                    <a href="#"> {{ $t("wallet.termService") }} </a>
                  </b-col>
                  <b-col col="4">
                    <a href="#"> {{ $t("wallet.privacyPolicy") }}</a>
                  </b-col>
                </b-row>
              </h6>
            </div>
            <div class="modal_bottom_shape">
              <span class="modal_bottom_shape_left"
                ><img src="@/assets/img/icon/hov_shape_L.svg" alt=""
              /></span>
              <span class="modal_bottom_shape_right"
                ><img src="@/assets/img/icon/hov_shape_L.svg" alt=""
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Connect Wallet Modal -->
</template>

<script>
export default {
  name: "ConnectModal",
  props: {
    address: Function,
  },
  computed: {
    walletType() {
      return this.$store.state.web3.walletType;
    },
    walletAddress() {
      let address = this.$store.state.user.coinbase;
      if (address)
        return (
          address.substring(0, 5) +
          "..." +
          address.substring(address.length - 5, address.length)
        );
      else return "";
    },
    connected() {
      return this.$store.state.connected;
    },
  },
  methods: {
    async connect(value) {
      this.$store.dispatch("connect", value).then((res) => {
        if (res && this.$tools.checkResponse(res)) {
          // this.$router.push("/home");
        }
      });
    },
    async disconnect(value) {
      this.$store.dispatch("disconnect", value);
    },
    close() {
      this.$emit("closeShow");
    },
  },
};
</script>

<style scoped lang="scss">
.connect_modal .modal_body p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  padding-bottom: 24px;
}

.connect_modal .modal-content .connect-section .heading-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.connect_modal .modal-content .connect-section .heading-list li {
  display: block;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  padding: 15px 30px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
}

.connect_modal .modal_body .connect-section .heading-list li span {
  /* width: 35px; */
  display: inline-block;
  margin-right: 20px;
}
.connect_modal .modal_body .connect-section .heading-list li span img {
  width: 35px;
}
.connect_modal .modal_body .connect-section .heading-list li:before {
  content: "\e902";
  position: absolute;
  top: 22px;
  right: 30px;
  color: rgba(255, 255, 255, 0.7);
  font-family: "icomoon";
  font-size: 10px;
}

.connect_modal .modal_body .connect-section .heading-list li a {
  color: #fff;
  font-family: "Russo One", sans-serif;
}
.contented {
  font-size: 14px;
}
.connect-section {
  min-height: 200px;
}

.disconnect {
  text-decoration: underline;
  font-size: 12px;
  color: #fff;
}
.address {
  /* color: #ffe600; */
  color: #00ffa3;
  text-decoration: underline;
}
.un-activate {
  -webkit-filter: grayscale(80%);

  -moz-filter: grayscale(80%);

  filter: grayscale(80%);
}

/* ------------------------------------
15. Modal Section CSS
---------------------------------------*/

.modal .modal-dialog {
  max-width: 440px;
  margin: auto;
  margin-top: 50px;
}

.modal-content {
  background: #4f1bc5;
  backdrop-filter: blur(5px);
  border-radius: 0px;
  border: none;
}

.modal_overlay {
  height: 100%;
  width: 100%;
  background: url(../assets/img/bg/modal_overlay.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 45px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
}

.modal_overlay2 {
  height: 100%;
  width: 100%;
  background: url(../assets/img/bg/modal2_overlay.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 45px;
  padding-bottom: 50px;
  position: relative;
}

.modal_bottom_shape {
}

.modal_bottom_shape .modal_bottom_shape_left {
  position: absolute;
  left: 10px;
  bottom: 10px;
  transform: rotate(-90deg);
}

.modal_bottom_shape .modal_bottom_shape_right {
  position: absolute;
  right: 10px;
  bottom: 10px;
  transform: rotate(180deg);
}

.modal_header button {
  background: transparent;
  border: none;
  outline: none;
  height: 45px;
  width: 45px;
  position: absolute;
  right: 0px;
  top: 0px;
  overflow: hidden;
}

.modal_header button:before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  height: 150%;
  width: 150%;
  position: absolute;
  right: -35px;
  top: -35px;
  transform: rotate(45deg);
}

.modal_header button i {
  position: absolute;
  bottom: 20px;
  right: 8px;
  color: #ffffff;
  font-size: 14px;
}

.modal_header h2 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 280px;
  margin: auto;
  margin-bottom: 26px;
}

.modal_body {
  padding: 0px;
}

.modal_body h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.modal_body h6 a {
  font-weight: 600px;
  color: #ffffff;
}
</style>
