<template>
  <div>
    <nav-bar />

    <div class="main">
      <div class="header"></div>
      <breadcrumb :crumbs="crumbs" />

      <div class="module ptb-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="sidebar-left pr-4">
                <!-- Categories widget-->
                <aside class="widget widget-categories">
                  <div class="widget-title">
                    <h6>Type</h6>
                  </div>
                  <ul>
                    <li>
                      <a
                        @click="leftClick(0)"
                        :class="leftIndex == 0 ? 'leftSelect' : ''"
                        >{{ $t("tool.wallet.title") }}
                        <span class="float-right"> EVM/Aptos</span></a
                      >
                    </li>
                    <li>
                      <a
                        @click="leftClick(1)"
                        :class="leftIndex == 1 ? 'leftSelect' : ''"
                        >{{ $t("tool.transferBatches") }}
                        <span class="float-right">POLYGON</span></a
                      >
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
            <BatchCreateWallet v-if="leftIndex == 0" />
            <BatchTransfer v-else-if="leftIndex == 1" />
          </div>
        </div>
      </div>
    </div>
    <site-footer />
    <copyright />
  </div>
</template>
<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/commons/SiteFooter";
import Copyright from "../../views/commons/Copyright";
import Breadcrumb from "../../views/commons/Breadcrumb";
import BatchCreateWallet from "./BatchCreateWallet.vue";
import BatchTransfer from "./BatchTransfer.vue";

export default {
  components: {
    Breadcrumb,
    SiteFooter,
    NavBar,
    Copyright,
    BatchCreateWallet,
    BatchTransfer,
  },
  data: function () {
    return {
      crumbs: [
        { link: "#", name: "Home", isActive: false },
        { link: "#", name: "Tool", isActive: true },
      ],
      leftIndex: 0,
    };
  },
  methods: {
    leftClick(index) {
      this.leftIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">

.leftSelect {
  color: #4f1bc5;
}
.header {
  background: linear-gradient(75deg, #4f1bc5, #7b17c5) !important;
  height: 80px;
}
</style>