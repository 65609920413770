export default {
  webLoading: false,
  currentRoute: null,
  currentView: null,
  token: null,
  connected: false,
  isLogin: false,
  heartbeatTimer: null,
  notice_unread: 0,
  config: {
    buyerFee: 0,
    ipfsUrl: "",
    networkId: 56,
    sellerFee: "",
  },
  web3: {
    address: null,
    coinbase: null,
    error: null,
    instance: null,
    isInjected: false,
    walletType: null,
    networkId: null,
  },
  ethBalance: "0",
  erc20Balance: {},
  user: {
    coinbase: "",
    avatar: "",
    brief: "",
    nickname: "",
    shortUrl: "",
    loginType: "",
    bannerUrl: "",
    id: "",
  },
  whiteListInfo: {},
  contractList: {
    0: '0x1354251c33ab106Db0965D14675dbeAc1e25016A'
  },
  payTokens: [],
  defalutPayToken: null,
  categorys: [],
  logs: [],
  logTimer: null,
};