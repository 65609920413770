var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"client-section",class:{
    'gray-light-bg': _vm.isGray,
    'pb-100': _vm.paddingBottom,
    'pt-100': _vm.paddingTop,
  },attrs:{"id":"media"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"section-heading text-center mb-5"},[_c('h2',[_vm._v(_vm._s(_vm.$t("customer.title")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("customer.desc"))+" ")])])])]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12"},[_c('carousel',{staticClass:"owl-theme clients-carousel dot-indicator owl-loaded owl-drag",attrs:{"items":1,"nav":false,"autoplay":true,"dots":false,"loop":true,"margin":15,"slideTransition":"linear","autoplayTimeout":4000,"autoplaySpeed":false,"smartSpeed":6000,"responsive":{
            0: { items: 2 },
            500: { items: 3 },
            600: { items: 4 },
            800: { items: 5 },
            1200: { items: 6 },
          }}},[_c('div',{staticClass:"item single-customer"},[_c('img',{staticClass:"customer-logo",attrs:{"src":require("../../../assets/img/cobak.png"),"alt":"client logo"}})]),_c('div',{staticClass:"item single-customer"},[_c('img',{staticClass:"customer-logo",attrs:{"src":require("../../../assets/img/bihu.png"),"alt":"client logo"}})]),_c('div',{staticClass:"item single-customer"},[_c('img',{staticClass:"customer-logo",attrs:{"src":require("../../../assets/img/lw.png"),"alt":"client logo"}})]),_c('div',{staticClass:"item single-customer"},[_c('img',{staticClass:"customer-logo",attrs:{"src":require("../../../assets/img/ldd.png"),"alt":"client logo"}})]),_c('div',{staticClass:"item single-customer"},[_c('img',{staticClass:"customer-logo",attrs:{"src":require("../../../assets/img/jscj.png"),"alt":"client logo"}})]),_c('div',{staticClass:"item single-customer"},[_c('img',{staticClass:"customer-logo",attrs:{"src":require("../../../assets/img/hxcj.png"),"alt":"client logo"}})]),_c('div',{staticClass:"item single-customer"},[_c('img',{staticClass:"customer-logo",attrs:{"src":require("../../../assets/img/8btc.png"),"alt":"client logo"}})]),_c('div',{staticClass:"item single-customer"},[_c('img',{staticClass:"customer-logo",attrs:{"src":require("../../../assets/img/tlcj.png"),"alt":"client logo"}})]),_c('div',{staticClass:"item single-customer"},[_c('img',{staticClass:"customer-logo",attrs:{"src":require("../../../assets/img/bkb.png"),"alt":"client logo"}})])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }