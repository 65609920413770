import Web3 from "web3";
import {
    AptosAccount
} from "aptos";

export default {

    async create(type, number) {
        if (type === "apt") {
            return await this.createAptos(number)
        } else {
            return await this.createEvm(number)
        }
    },

    async createEvm(num) {
        let web3 = new Web3();
        let walletList = [];
        for (let i = 0; i < num; i++) {
            let result = await web3.eth.accounts.create();
            walletList.push(result)
        }
        return walletList;
        // this.printResult("EVM", walletList)
    },

    async createAptos(num) {
        let walletList = [];
        for (let i = 0; i < num; i++) {
            const account = await new AptosAccount();
            walletList.push({
                address: account.toPrivateKeyObject().address,
                privateKey: account.toPrivateKeyObject().privateKeyHex
            })
        }
        // this.printResult("Aptos", walletList)
        return walletList;
    },

    printResult(type, walletList) {
        for (let i = 0; i < walletList.length; i++) {
            console.log(`${type} wallet ${i + 1} :`)
            console.log(`address: ${walletList[i].address}`);
            console.log(`privateKey: ${walletList[i].privateKey}`)
            console.log("")
        }
    }
}