<template>
  <div>
    <nav-bar :colored-logo="true" />
    <div class="main">
      <banner />
      <promo :is-gray="true" />
      <offerings />
      <!-- <counter-section /> -->
      <customer :is-gray="true" />
      <features />
      <games />
      <talk />
      <phone />
      <partners />
      <platform />
      <!-- <screenshots :padding-top="false" /> -->
      <work-process :is-gray="true" />
      <!-- <price /> -->
      <!-- <faq :is-gray="true" /> -->
      <!-- <team /> -->
      <!-- <testimonial /> -->
      <!-- <blog :is-gray="true" /> -->
      <!-- <contact /> -->
    </div>
    <site-footer :show-subscribe="false" />
    <!-- <copyright /> -->
  </div>
</template>

<script>
import Partners from "../../views/index-twelve/Partners.vue"
import Phone from "../../views/idnex-eleven/phone.vue"
import Talk from "../../views/idnex-eleven/talk.vue"
import Games from "../../views/idnex-eleven/games.vue";
import NavBar from "../../views/commons/NavBar.vue";
import Banner from "../../views/index-four/Banner.vue";
import Offerings from "../../views/about-us/Offerings.vue";
import Promo from "../../views/index-two/Promo";
import Platform from "../../views/index-two/Platform";
import Features from "../../views/index-four/Features";
// import Price from "../../views/index-one/Price";
// import Screenshots from "../../views/index-one/Screenshots";
import WorkProcess from "../../views/commons/sections/WorkProcess";
import SiteFooter from "../../views/commons/SiteFooter.vue";
// import Copyright from "../../views/commons/Copyright.vue";
import Customer from "../../views/commons/sections/Customers.vue";
// import Faq from "../../views/index-one/Faq";
// import Testimonial from "../../views/index-one/Testimonial";
// import Contact from "../../views/index-one/Contact";
// import Blog from "../../views/index-one/Blog";
// import CounterSection from "../../views/index-one/CounterSection";
// import Team from "../../views/commons/sections/Team";

export default {
  name: "IndexFour",
  components: {
    NavBar,
    Banner,
    Promo,
    Offerings,
    Talk,
    Phone,
    Platform,
    // Screenshots,
    Features,
    WorkProcess,
    // Price,
    Games,
    SiteFooter,
    // Copyright,
    Customer,
    Partners,
    // Faq,
    // Testimonial,
    // Blog,
    // Contact,
    // CounterSection,
    // Team
  },
  mounted() {
    const anchor = this.$route.params.anchor;
    if (anchor) {
      setTimeout(() => {
        let el = document.querySelector("#" + anchor);
        // console.log(anchor);
        // chrome
        document.body.scrollTop = el.offsetTop - 80;
        // firefox
        document.documentElement.scrollTop = el.offsetTop - 80;
      }, 500);
    }
  },
};
</script>